import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Conda, to the point",
  "component": "none",
  "date": "2020-03-06T22:45:13.234Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "installation-via-anaconda",
      "style": {
        "position": "relative"
      }
    }}>{`Installation (via Anaconda)`}<a parentName="h3" {...{
        "href": "#installation-via-anaconda",
        "aria-label": "installation via anaconda permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.anaconda.com/anaconda/install/mac-os/"
        }}>{`macOS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.anaconda.com/anaconda/install/windows/"
        }}>{`Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.anaconda.com/anaconda/install/linux/"
        }}>{`Linux`}</a></li>
    </ul>
    <div className="gap-10"></div>
    <h3 {...{
      "id": "environment",
      "style": {
        "position": "relative"
      }
    }}>{`Environment`}<a parentName="h3" {...{
        "href": "#environment",
        "aria-label": "environment permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Create an environment containing a collection of conda packages.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda create --name <environment name> <packages e.g. python=3.5>`}</span></span></span></code></pre>
    <p>{`Create from an `}<inlineCode parentName="p">{`environment.yml`}</inlineCode>{` file with the command.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda env create -f environment.yml`}</span></span></span></code></pre>
    <h3 {...{
      "id": "workflow",
      "style": {
        "position": "relative"
      }
    }}>{`Workflow`}<a parentName="h3" {...{
        "href": "#workflow",
        "aria-label": "workflow permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`List available environments.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda info --envs`}</span></span></span></code></pre>
    <p>{`Activate an environment.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "3"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda activate <environment name>`}</span></span></span></code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: Active environments are marked with an asterisk (*) in the terminal.`}</p>
    </blockquote>
    <p>{`Optionally `}<a parentName="p" {...{
        "href": "https://docs.conda.io/projects/conda/en/latest/user-guide/tasks/manage-environments.html"
      }}>{`stack`}</a>{` active environments. `}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "4"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda activate --stack <environment name>`}</span></span></span></code></pre>
    <p>{`Deactivate the current environment.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "5"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda deactivate`}</span></span></span></code></pre>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Search for a package to add. `}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "6"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda search -f <package name>`}</span></span></span></code></pre>
    <p>{`Install a package in the current active environment.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "bash",
      "data-index": "7"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`conda install <package name>`}</span></span></span></code></pre>
    <p>{`Install a specific version of the package `}<inlineCode parentName="p">{`conda install pyspark=2.3.2=py37_0`}</inlineCode>{`. Install the package in an environment different from the current active environment `}<inlineCode parentName="p">{`conda install --name <env name> <package name>`}</inlineCode>{`.`}</p>
    <div className="gap-20"></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c7219a665ae67b55ff88b56345dff8d3/9b601/markus-spiske-466ENaLuhLY-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAByCIYx//EABoQAAICAwAAAAAAAAAAAAAAAAABEBESIUH/2gAIAQEAAQUCeIqNRw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAQIDH/2gAIAQEABj8CET//xAAeEAACAgEFAQAAAAAAAAAAAAAAAREhQTFRcYGh0f/aAAgBAQABPyGilL4IMdyO/wBDdtZ3PQ9T/9oADAMBAAIAAwAAABBvz//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EKf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAHBABAQACAgMAAAAAAAAAAAAAAREAMSFRQXGB/9oACAEBAAE/EICyQ6XrFWyjpCesaVT4ri6LJG9/Mu0lcJnFl1xn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Monitor with data or code",
            "title": "Monitor with data or code",
            "src": "/static/c7219a665ae67b55ff88b56345dff8d3/b4294/markus-spiske-466ENaLuhLY-unsplash.jpg",
            "srcSet": ["/static/c7219a665ae67b55ff88b56345dff8d3/75985/markus-spiske-466ENaLuhLY-unsplash.jpg 150w", "/static/c7219a665ae67b55ff88b56345dff8d3/f93b5/markus-spiske-466ENaLuhLY-unsplash.jpg 300w", "/static/c7219a665ae67b55ff88b56345dff8d3/b4294/markus-spiske-466ENaLuhLY-unsplash.jpg 600w", "/static/c7219a665ae67b55ff88b56345dff8d3/8e1fc/markus-spiske-466ENaLuhLY-unsplash.jpg 900w", "/static/c7219a665ae67b55ff88b56345dff8d3/e5166/markus-spiske-466ENaLuhLY-unsplash.jpg 1200w", "/static/c7219a665ae67b55ff88b56345dff8d3/9b601/markus-spiske-466ENaLuhLY-unsplash.jpg 5760w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div className="gap-20"></div>
    <h2 {...{
      "id": "more-information",
      "style": {
        "position": "relative"
      }
    }}>{`More Information`}<a parentName="h2" {...{
        "href": "#more-information",
        "aria-label": "more information permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Conda is an open source general purpose package manager. It is used widely throught the python community by scientists and developers. Conda is not limited to managing packages for Python. Conda can install and manage packages from `}<inlineCode parentName="p">{`repo.continuum.io`}</inlineCode>{`. Maintained by the Anaconda team and the default channel.`}</p>
    <p>{`Conda environments are directories containing a collection of installed conda packages. Described in the `}<inlineCode parentName="p">{`environment.yml`}</inlineCode>{` file. One can change environments by activating or deactivating them. The `}<inlineCode parentName="p">{`base`}</inlineCode>{` environment in the `}<inlineCode parentName="p">{`env`}</inlineCode>{` folder is the default environment. Separate environments allow users to keep packages isolated from each other.`}</p>
    <p>{`Stack the environments to leave the current environment’s `}<em parentName="p">{`PATH`}</em>{` entries in place so that you can continue to access command-line programs from the first environment.`}</p>
    <p>{`Packages are a compressed tarball or bzipped tar archive file conatining metadata under the `}<inlineCode parentName="p">{`info`}</inlineCode>{` directory and system-level libraries. Such as; python modules, executable programs, and other components. Packages are downloaded from channels.`}</p>
    <p>{`The main configuration file, `}<inlineCode parentName="p">{`.condarc`}</inlineCode>{`, is a runtime configuration file. The file follows the YAML syntax and can be found at;`}</p>
    <ul>
      <li parentName="ul">{`user home directory`}</li>
      <li parentName="ul">{`root environment directory (overrides user home dir located file)`}</li>
    </ul>
    <div className="gap-20"></div>
    <blockquote>
      <h2 parentName="blockquote" {...{
        "id": "references",
        "style": {
          "position": "relative"
        }
      }}>{`References`}<a parentName="h2" {...{
          "href": "#references",
          "aria-label": "references permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h2>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://conda.io/projects/conda/en/latest/user-guide/index.html"
            }}>{`Conda User Guide Documentation`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://docs.conda.io/projects/conda/en/latest/user-guide/tasks/manage-environments.html"
            }}>{`Conda Stacks`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://jakevdp.github.io/blog/2016/08/25/conda-myths-and-misconceptions/"
            }}>{`Conda Myths`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Image by `}<a style={{
              "backgroundColor": "black",
              "color": "white",
              "textDecoration": "none",
              "padding": "4px 6px",
              "fontFamily": "-apple-system, BlinkMacSystemFont, \"San Francisco\", \"Helvetica Neue\", Helvetica, Ubuntu, Roboto, Noto, \"Segoe UI\", Arial, sans-serif",
              "fontSize": "12px",
              "fontWeight": "bold",
              "lineHeight": "1.2",
              "display": "inline-block",
              "borderRadius": "3px"
            }} href="https://unsplash.com/@markusspiske?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Markus Spiske"><span style={{
                "display": "inline-block",
                "padding": "2px 3px"
              }}><svg xmlns="http://www.w3.org/2000/svg" style={{
                  "height": "12px",
                  "width": "auto",
                  "position": "relative",
                  "verticalAlign": "middle",
                  "top": "-2px",
                  "fill": "white"
                }} viewBox="0 0 32 32"><title>{`unsplash-logo`}</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></span><span style={{
                "display": "inline-block",
                "padding": "2px 3px"
              }}>{`Markus Spiske`}</span></a></p>
        </li>
      </ul>
    </blockquote>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .dark-default-dark {
    background-color: #1E1E1E;
    color: #D4D4D4;
  }
  .dark-default-dark .mtk1 { color: #D4D4D4; }
  .dark-default-dark .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      